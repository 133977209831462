
$bounce-offset: -10px;

.bounce {
  animation: bounce 4s infinite 1s;
}

@keyframes bounce {
  70% {
    transform: translateY(0);
  }
  80% {
    transform: translateY($bounce-offset * 2);
  }
  85% {
    transform: translateY(0);
  }
  90% {
    transform: translateY($bounce-offset);
  }
  100% {
    transform: translateY(0);
  }
}