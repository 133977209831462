@import "styles/includes";

.grid {
  display: flex;
  align-items: center;
  margin: -20px;
  @media (max-width: $md) {
    flex-direction: column;
  }
}

.item {
  width: 50%;
  flex-shrink: 0;
  padding: 0 20px;

  @media (max-width: $md) {
    width: 100%;
  }

  h2 {
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 30px;
    padding-right: 24%;
    @media (max-width: $lg) {
      padding-right: 30px;
    }
    @media (max-width: $md) {
      padding-right: 0px;
    }
  }
}

.itemInner {
  position: relative;
  z-index: 1;
}

.imageContainer {
  @media (max-width: $md) {
    max-width: 500px;
    margin: 50px auto;
  }
}

.imageWrapper {
  position: relative;
  padding-top: 56.25%;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.playIcon {
  width: 20%;
  transform: scale(.95);
  transition: 0.3s;
  will-change: transform;
  svg {
    width: 100%;
  }
  &:hover {
    transform: scale(1);
  }
}

.videoImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.imageOne {
  transform: translate(-28%, 0) scale(.82);
  @media (max-width: $lg) and (min-width: calc($md + 1px)) {
    transform: translate(-20%, 0) scale(.82);
  }
  filter: blur(2px);
  opacity: .75;
}

.imageTwo {
  transform: translate(23%, 0) scale(.68);
  @media (max-width: $lg) and (min-width: calc($md + 1px)) {
    transform: translate(18%, 0) scale(.68);
  }
  filter: blur(7px);
  opacity: .5;
}