a {
  text-decoration: none;
  transition: .3s;
}

p {
  a {
    color: inherit;
    transition: none;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  height: 35px;
  font-size: 16px;
  padding: 0 10px;
  border: 1px solid $brand-red;
  text-transform: uppercase;
  transition: .3s;
  color: $white;
  svg {
    margin-left: 10px;
    display: block;
  }
  &:hover {
    background-color: $brand-red;
  }
  @media (max-width: $lg) {
    font-size: 14px;
    height: 30px;
    padding: 0 6px;
  }
}