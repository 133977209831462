.desktop {
  @media(max-width: $lg) {
    display: none;
  }
}
.mobile {
  display: none;
  @media(max-width: $lg) {
    display: block;
  }
}