@import "styles/includes";

.modal {
  position: fixed;
  z-index: 1040;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border: 2px solid $brand-red;
  background-color: $black;
  color: $white;
}

.modalInner {
  width: calc(100vw - 40px);
  padding: 80px;
  max-width: 800px;

  @media (max-width: $md) {
    padding: 40px;
  }
  @media (max-width: $sm) {
    padding: 20px;
    max-width: 400px;
  }
  @media (max-width: $sm) and (max-height: 668px) {
    height: calc(100vh - 40px);
    overflow: auto;
  }
}

.animate {
  opacity: 0;
  transform: translate(-50%,-75%);
  transition: 500ms;
}

.show {
  opacity: 1;
  transform: translate(-50%,-50%);
}

.background {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $black-opaque-dark;
  &.animate {
    transition: opacity 500ms;
  }
  &.show {
    transform: translate(0%,0%);
  }
}

.close {
  position: absolute;
  top: 14px;
  right: 14px;
  will-change: transform;
  svg {
    transition: .2s;
    transform: rotate(0deg);
    pointer-events: none;
  }
  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }
}

.grid {
  display: flex;
  margin: 0 -25px;
  @media (max-width: $sm) {
    flex-direction: column;
    margin: 0;
  }
}

.item {
  width: 50%;
  padding: 0 25px;
  @media (max-width: $sm) {
    width: 100%;
    padding: 10px 0;
  }

  h3 {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 25px;
  }
}

.image {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;
}