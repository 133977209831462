@import "styles/includes";

.gradients {
  background-color: $black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}


.gradient {
  position: absolute;
  width: 70vw;
  height: 100vh;
  transition: opacity 1s;
  @media (max-width: $md) {
    width: 100vw;
    height: 100vw;
  }
}

.left {
  bottom: 0;
  left: 0;
}

.orange {
  background: linear-gradient(46.67deg,#ff7828 -38.52%,rgba(255,120,40,0) 50.26%);
}

.purple {
  background: linear-gradient(46.67deg,#78289b -38.52%,rgba(120,40,155,0) 50.26%);
}

.right {
  top: 0;
  right: 0;
}

.blue {
  background: linear-gradient(226.67deg,#005fe6 -38.52%,rgba(0,95,230,0) 50.26%);
}

.red {
  background: linear-gradient(226.67deg,#e50914 -38.52%,rgba(180,20,95,0) 50.26%);
}