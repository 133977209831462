@import 'styles/includes';

h1,.h1,
h2,.h2,
h3,.h3 {
  font-weight: 700;
  line-height: 1.3;
}

h1,.h1 {
  font-size: clamp(40px, 4vw, 72px);
  font-weight: 700;
  line-height: 1.1;
  @media (max-width: $sm) {
    font-size: 36px;
  }
}

h2,.h2 {
  // font-size: 42px;
  font-size: clamp(28px, 3vw, 42px);
  font-weight: 700;
  line-height: 1.1;
}

h3,.h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1.1;
  @media (max-width: $lg) {
    font-size: 20px;
  }
}

p, a, li {
  line-height: 1.1;
}

p {
  font-size: 18px;
  line-height: 1.3;
  @media (max-width: $lg) {
    font-size: 16px;
  }
  @media (max-width: $lg) {
    font-size: 15px;
  }
}

p.featured {
  font-size: 30px;
  @media (max-width: $lg) {
    font-size: 24px;
  }
  @media (max-width: $sm) {
    font-size: 18px;
  }
}



.t-center {
  text-align: center;
}