@import "styles/includes";

.mobileMenu {

  display: none;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 300;

  @media (max-width: $lg) {
    display: block;
  }

  &.open {
    nav {
      transform: translateX(-100%);
    }
  }

  nav {
    background-color: $black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    width: 220px;
    height: 100vh;
    flex-direction: column;
    padding: 80px 20px 10px;
    transition: .3s;
  }

  hr {
    background-color: $gray;
    height: 1px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.menuButton {
  position: absolute;
  z-index: 400;
  top: 30px;
  right: 20px;

  span {
    display: block;
    background-color: $white;
    width: 20px;
    height: 1px;
    margin: 6px 0;
    transition: .3s;
  }

  &.close {
    span {
      &:nth-child(1) {
        transform:  translateY(7px) rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform:  translateY(-7px) rotate(45deg);
      }
    }
  }
}

.navTitle {
  color: $gray;
  text-transform: uppercase;
  &.active, &:hover {
    color: $brand-red;
  }
}

.navLink {
  color: $gray;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;

  &.active, &:hover {
    color: $white;
  }
}