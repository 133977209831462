@import "styles/includes";

.root {
  padding-bottom: 30px;
  margin: -150px 0 -100px;   // Soak up some of the extra vertical space above/below
  
}

.grid {
  display: flex;
  align-items: center;
  margin: -20px;
  @media (max-width: $md) {
    flex-direction: column;
  }
}

.item {
  width: 50%;
  padding: 0 20px;
  @media (max-width: $md) {
    width: 100%;
  }

  h2 {
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 15px;
  }

  strong {
    font-weight: bold;
  }
}

.buttonWrapper {
  margin-top: 40px;
}

.itemImage {
  margin-bottom: 100px;
  padding-right: 60px;
  img {
    margin-top: 10vh;
  }
  svg {
    margin-top: 10vh;
    width: 100%;
    height: auto;
  }
  @media (max-width: $md) {
    padding-right: 30px;
    margin-bottom: 120px;
  }
}