$font-family: Netflix Sans,Helvetica Neue,Helvetica,Arial,sans-serif;

$black: #000000;
$gray: #A2A2A2;
$gray-dark: #707070;
$white: #ffffff;

$brand-red: #E50914;

$black-opaque: rgba(0,0,0, .2);
$black-opaque-dark: rgba(0,0,0, .6);

$xxl: 1440px;
$xl: 1280px;
$lg: 1024px;
$md: 768px;
$sm: 600px;
$xs: 450px;