
@import 'styles/includes';

.header {
  position: fixed;
  z-index: 200;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 140px;
  background-color: transparent;
  transition: background-color .5s;

  @media (max-width: $xxl) {
    padding: 25px 50px;
  }

  @media (max-width: $lg) {
    nav {
      display: none;
    }
    .right {
      display: none;
    }
    padding: 25px 24px;
  }
}

.filled {
  background-color: $black;
}

.inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
}

.mobile {
  display: none;
}

.logo {
  width: 25px;
  margin-right: 60px;
  @media (max-width: $xl) {
    margin-right: 30px;
  }
}

.navLink {
  font-size: 18px;
  font-weight: 500;
  color: $gray;
  padding: 0 40px;
  transition: color .3s;

  &.active, &:hover {
    color: $white;
  }

  @media (max-width: $xl) {
    padding: 0 20px;
  }
}

.navTitle {
  color: $white;
  text-transform: uppercase;
  transition: .3s;
  &:hover {
    color: $brand-red;
  }
}