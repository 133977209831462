@import 'styles/includes';

.root {
  padding: 0;
  min-height: unset;
}

.box {
  padding: 20px;
  margin: 0 40px 15px;
  text-align: center;

  p {
    font-size: 15px;
  }

  strong {
    font-weight: bold;
  }

  @media (max-width: 1024px) {
    margin: 0px 0px 10px;
    padding: 20px 0;
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 650px) {
    padding: 10px;
    p {
      font-size: 12px;
    }
  }
}

.container {
  @media (max-width: 650px) {
    padding: 0 15px;
  }
}