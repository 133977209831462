@import "styles/includes";



.root {
  padding-top: 200px;
  padding-bottom: 250px;
  min-height: unset;
}

.container {
  width: 100%;
}

.header {
  margin-bottom: 50px;
  @media (max-width: $sm) {
    max-width: 380px;
    // margin-left: auto;
    // margin-right: auto;
  }
}

.grid {
  display: flex;
  margin: 0 -30px;
  flex-wrap: wrap;
  justify-content: left;
}

.item {
  // width: calc(100% / 3);
  padding: 0 30px 30px;

  @media (max-width: $md) {
    width: 50%;
  }
  @media (max-width: $sm) {
    width: 100%;
    max-width: 380px;
  }

  p {
    font-size: 16px;
    margin-top: 10px;
    max-width: 340px;

    @media (max-width: $lg) {
      font-size: 14px;
    }
  }

  a {
    // margin-top: 20px;
  }
}

.imageWrapper {
  position: relative;
  padding-top: calc((280 / 360) * 100%);
  margin-bottom: 20px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}