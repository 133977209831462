@import "styles/includes";

.container {
  max-width: 1325px;
  padding: 0 50px;
  margin: 0 auto;
  @media (max-width: $md) {
    padding: 0 30px;
  }
}

.slide {
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 120px 0 150px;
}