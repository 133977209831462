@import "styles/includes";

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  
  color: $gray-dark;
  padding: 0 140px;

  @media (max-width: $xxl) {
    padding: 0 50px;
  }
  @media (max-width: $lg) {
    padding: 0 30px;
  }

  svg {
    width: 100px;
    margin-right: 20px;
    @media(max-width: $sm) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 14px;
    text-align: right;
    @media (max-width: $md) {
      font-size: 12px;
    }
    @media(max-width: $sm) {
      text-align: center;
    }
  }
}

.footerInner {
  border-top: 1px solid $gray-dark;
  padding: 20px 0 40px;
  display: flex;
  justify-content: space-between;
  @media(max-width: $sm) {
    flex-direction: column;
    align-items: center;
  }
}