
@import 'styles/includes';

.hero {
  position: relative;
  z-index: 100;
}

.videoContainer, .videoOverlay, .videoPlayer, .heroContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoOverlay {
  background-color: $black-opaque;
}

.videoPlayer {
  video {
    object-fit: cover;
  }
}

.heroContent {
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    padding: 0 30px;
  }
}

.seperator {
  width: 110px;
  border-top: 1px solid $white;
  margin: 50px auto 30px;
  @media (max-width: $lg) {
    margin: 30px auto;
    width: 80px;
  }
}

.textWrapper {
  max-width: 870px;
  padding: 0 30px;
  margin: 0 auto 10px;
}

.disclaimerWrapper {
  max-width: 1000px;
  margin: 0 auto 10px;
}