@import 'scss-reset/_reset.scss';
@import 'styles/global/fonts.scss';
@import 'styles/global/type.scss';
@import 'styles/global/links.scss';
@import 'styles/global/helpers.scss';
@import 'styles/global/animation.scss';

html, body {
  font-family: $font-family;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: instant;
}

* {
  box-sizing: border-box;
  &:focus-visible {
    box-shadow: 0 0 2px 2px #51a7e8;
  }
}