@font-face{
  font-family:'Netflix Sans';
  src:url('../../assets/fonts/NetflixSans_W_Rg.woff2') format('woff2'), 
      url('../../assets/fonts/NetflixSans_W_Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family:'Netflix Sans';
  src:url('../../assets/fonts/NetflixSans_W_Md.woff2') format('woff2'), 
      url('../../assets/fonts/NetflixSans_W_Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face{
  font-family:'Netflix Sans';
  src:url('../../assets/fonts/NetflixSans_W_Bd.woff2') format('woff2'), 
      url('../../assets/fonts/NetflixSans_W_Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}